/**
 * @author johan.magnusson@svenskaspel.se (Johan Magnusson)
 */

import PropTypes from 'prop-types';
import './../../stylesheets/dropdown.less';

const DropdownItem = ({ disabled, children, selected, onClick }) => {
  const classNames = ['js-dropdown-list-item', 'dropdown-list-item'];
  if (disabled) {
    classNames.push('dropdown-item-disabled');
  }
  if (selected) {
    classNames.push('dropdown-item-selected');
  }
  return (
    <div className={classNames.join(' ')} onClick={onClick} role="listitem" tabIndex="-1">
      <div className="dropdown-header">
        {children}
      </div>
    </div>
  );
};

DropdownItem.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool
};

DropdownItem.defaultProps = {
  disabled: false,
  selected: false
};

export default DropdownItem;
